import DatePicker from "vue2-datepicker";
import toggleSwitch from "@/components/toggle-switch";
import {DateTime} from "luxon";
import VueMultiselect from "@/components/vue-multiselect";
import monitoringCascadingTimeline from "@/components/monitoring-cascading-timeline";
import monitoringDifferentialHighlighter from "@/components/monitoring-differential-highlighter";
import {mapActions, mapGetters} from "vuex";
import {uniqBy} from "lodash";
import {} from "vuex";
import axios from "@/axios";
import {getPlatformURL} from "@/utils/functions";
import OsintAxios from "@/axios/osint.js";

export default {
    name: "monitoring-filter",
    components: {
        DatePicker,
        toggleSwitch,
        VueMultiselect,
        monitoringCascadingTimeline,
        monitoringDifferentialHighlighter,
    },
    props: {
        filterNewResults: {
            type: Boolean,
            default: false,
        },
        dateFilters: {
            type: Object,
            default: {},
        },
    },

    data() {
        return {
            dateRange: [],
            selectedCategory: null,
            selectedDataType: null,
            dataTypeOp: [
                {label: "All Results", value: "All"},
                {label: "Unacknowledged Results", value: "New"},
                {label: "Acknowledged Results", value: "Done"},
            ],
            prettify: function(ts) {
                return new Date(ts).toLocaleDateString("en", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                });
            },
            scrollerVisible: false,
            showComparisonDifferentials: false,
            monitoringDocs: [],
            differentials: null,
        };
    },
    watch: {
        "dateFilters.data.timeline": function() {
            this.updateComparisonReferences();
        },
    },
    computed: {
        ...mapGetters(["getAllToolNames", "getCaseCreationDate", "getMonitoringDateFilter", "searchedQueries"]),
        isMonitoringTab() {
            return this.$route.name == "monitoring";
        },
        formattedDate() {
            let formatDate = ["Starting Date", "Ending Date"];
            if (this.dateRange.length > 0) {
                let start = this.dateRange[0];
                start = DateTime.fromMillis(start);
                start = start.toFormat("dd/MM/yyyy");

                let end = this.dateRange[1];
                end = DateTime.fromMillis(end);
                end = end.toFormat("dd/MM/yyyy");

                formatDate = [start, end];
                return formatDate;
            } else return formatDate;
        },
        monitoringTools() {
            let options = [];
            if (this.getAllToolNames?.children?.length > 0) {
                options = this.getAllToolNames.children?.filter((tool) => tool.monitoring).map((tool) => ({name: tool.heading, value: tool.heading}));
            }
            return [{group: "Category", categories: uniqBy(options, "name")}];
        },
        cardComponent() {
            let cardComponent = "";
            switch (this.$route.params.toolName) {
                case "adverse-media-tool":
                case "offshoreleaks-tool":
                    cardComponent = "adverse-media-expand";
                    break;
                case "news-tool":
                    cardComponent = "neo-news-expanded";
                    break;
                case "company-name":
                    cardComponent = "neo-corporate-records-more";
                    break;
            }
            return cardComponent;
        },
    },

    mounted() {
        if (this.dateRange.length === 0) {
            let today = new Date();
            let created_date;
            if (this.getCaseCreationDate !== "") created_date = new Date(this.getCaseCreationDate);
            else created_date = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
            const dates = [created_date, today];
            this.dateRange = dates.map((e) => Date.parse(e));
            this.setDatefilter(dates);
        }
        // PREFETCH DATA TYPE MULTISELECT VALUE
        if (this.getMonitoringDateFilter && this.getMonitoringDateFilter.dataType) this.selectedDataType = this.dataTypeOp.find((el) => el.value === this.getMonitoringDateFilter.dataType);
        else this.selectedDataType = {label: "Unacknowledged Results", value: "New"};
    },

    methods: {
        ...mapActions(["getNewMonitoringEvents"]),
        getPlatformURL,
        handleFilterResults() {
            this.$emit("filterMonitoring");
        },
        setCategory() {
            this.$emit("handleCategory", this.selectedCategory);
        },
        limitText(length) {
            return `${length} items selected`;
        },
        async setDatefilter(dt) {
            this.$emit("setDateFilter", dt);
            await this.getNewMonitoringEvents();
        },
        handleDataType(val) {
            this.$emit(`setDataType`, val);
        },
        async compareReferences() {
            this.monitoringDocs =
                this.$refs?.["timeline-slider"]?.comparison_references?.sort((doc1, doc2) => {
                    return doc1.api_all_data.monitoringDate > doc2.api_all_data.monitoringDate ? 1 : -1;
                }) || [];
            if (this.monitoringDocs.length < 2) {
                this.$toast.error("Please select atleast 2 references for comparison");
                return;
            } else if (this.monitoringDocs.length > 2) {
                this.$toast.error("Maximum 2 references are allowed for comparison");
                return;
            }
            await this.getMonitoringDifferentials();
            this.showComparisonDifferentials = !this.showComparisonDifferentials;
            this.$modal.show("monitoring-differential-highlighter");
        },
        updateComparisonReferences() {
            let refs = this.$refs?.["timeline-slider"]?.comparison_references;
            const timeline = this.dateFilters.data.timeline;
            if (refs?.length) {
                refs = refs.map((ref) => {
                    const match = timeline.findIndex((doc) => doc.api_all_data.doc_id === ref.api_all_data.doc_id);
                    if (match > -1) return timeline[match];
                    else return ref;
                });
                this.$refs["timeline-slider"].comparison_references = refs;
                this.monitoringDocs =
                    refs?.sort((doc1, doc2) => {
                        return doc1.api_all_data.monitoringDate > doc2.api_all_data.monitoringDate ? 1 : -1;
                    }) || [];
            }
        },
        async getMonitoringDifferentials() {
            let toolName = "";
            let reqBody = {
                doc_id1: this.monitoringDocs[0].api_all_data.doc_id,
                doc_id2: this.monitoringDocs[1].api_all_data.doc_id,
                source: this.monitoringDocs[0].api_all_data.doc_source,
            };
            switch (this.$route.params.toolName) {
                case "company-name":
                    toolName = "corporate-records";
                    const query = this.searchedQueries.find((el) => el._id === this.monitoringDocs[0].api_all_data.query_id);
                    const keys = Object.keys(query?.query);
                    const type = keys.includes("officer") ? "officer" : keys.includes("company") ? "company" : keys.includes("address") ? "address" : "company";
                    reqBody = {
                        ...reqBody,
                        search_type: type === "name" ? "company" : type,
                    };
                    break;
                case "news-tool":
                    toolName = "news";
                    break;
                case "adverse-media-tool":
                case "offshoreleaks-tool":
                    toolName = "adverse";
                    if (reqBody.source === "dowjones")
                        reqBody = {
                            ...reqBody,
                            dowjones_source: this.monitoringDocs[0].api_all_data.sources[0],
                        };
                    break;
            }
            const {data: differentials} = await axios.post(`/data-monitoring/diff/${toolName}`, reqBody);
            if (this.monitoringDocs[0]?.api_all_data?.sources?.includes("wl") || this.monitoringDocs[0]?.api_all_data?.sources?.includes("soe")) {
                this.monitoringDocs[0].api_all_data.sources.map((el) => {
                    this.monitoringDocs[0].api_all_data[el] = {...this.monitoringDocs[0].api_all_data[el], ...differentials[this.monitoringDocs[0].api_all_data.doc_id]?.detail[el]};
                });
            }
            if (this.monitoringDocs[1]?.api_all_data?.sources?.includes("wl") || this.monitoringDocs[1]?.api_all_data.sources?.includes("soe")) {
                this.monitoringDocs[1].api_all_data.sources.map((el) => {
                    this.monitoringDocs[1].api_all_data[el] = {...this.monitoringDocs[1].api_all_data[el], ...differentials[this.monitoringDocs[1].api_all_data.doc_id]?.detail[el]};
                });
            }

            this.differentials = differentials;
        },
        closeDifferentials() {
            this.monitoringDocs = [];
            this.showComparisonDifferentials = false;
            this.differentials = null;
            this.$modal.hide("monitoring-differential-highlighter");
        },
    },
};
